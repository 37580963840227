import { render, staticRenderFns } from "./AddQuotationList.vue?vue&type=template&id=54ab85ff&scoped=true&"
import script from "./AddQuotationList.vue?vue&type=script&lang=js&"
export * from "./AddQuotationList.vue?vue&type=script&lang=js&"
import style0 from "./AddQuotationList.vue?vue&type=style&index=0&id=54ab85ff&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54ab85ff",
  null
  
)

export default component.exports